.react-icons {
  vertical-align: middle;
}

.table-hover tbody tr:hover td {
  background: orangered;
  color: white;
  font-weight:600;
}

.custom-class {
  table-layout:auto;
}